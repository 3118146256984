<template>
  <div class="view-introduction">
    <b-overlay
      class="intro"
      :show="page === undefined"
      z-index="0"
    >
      <page-view
        v-if="page"
        :page="page" slug="intro"
        @close="$router.push({ name: 'home' })"
      />
    </b-overlay>
  </div>
</template>

<script>
import { PageView } from '@/components/layouts'


export default {
  name: 'Introduction',

  components: {
    PageView
  },

  data () {
    return {
      page: undefined
    }
  },

  async created () {
    this.page = await this.$store.dispatch('QUERY_PAGE', 'intro')
  }
}
</script>

<style lang="scss" scoped>
.view-introduction {
  height: 100%;
  position: relative;

  &.overflow-hidden {
    overflow: hidden;
  }
}

.intro {
  height: 100%;

  .page-intro {
    font-family: $font-family-text;
    font-size: 1.5rem;

    @include media-breakpoint-up($size-bp) {
      font-size: 2rem;
    }

    @include media-breakpoint-up($layout-bp) {
      ::v-deep .page-wrapper {
        max-width: 70%;
        margin: auto;
      }
    }
  }
}
</style>
